<template>
  <b-row>
    <b-col
      cols="12"
    >
      <router-link
        :to="{ name: 'accounts-edit' }"
        class="btn btn-danger btn-sm"
        variant="danger"
        style="float: right; margin-bottom: 10px; color: #fff"
      >{{ $t('Add') }}
      </router-link>
    </b-col>
    <b-col
      cols="12"
      class="form_custom"
    >
      <b-card-code title="">
        <b-row>
          <b-col cols="3">
            <b-button
              variant="outline-danger"
              class="position-absolute position-bottom-0 btn-sm"
              :disabled="!ids.length"
              @click="deleteMulti"
            >
              <feather-icon
                icon="Trash2Icon"
                class="mr-50"
              />
              <span class="align-middle">{{ $t('Delete') }}</span>
            </b-button>
          </b-col>
          <b-col cols="3" />
          <b-col md="3">
            <label for="source">
              Nguồn
            </label>
            <b-form-select
              v-model="filter.source"
              :options="sourceOption"
              @change="loadItems"
            />
          </b-col>
          <b-col md="3">
            <label for="">
              {{ $t("Search") }}
            </label>
            <b-form-input
              v-model="filter.key"
              :placeholder="$t('search_key')"
              @change="loadItems"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <table
              class="table b-table"
              style="margin-top: 10px"
            >
              <thead>
                <tr>
                  <th width="10">
                    <b-form-checkbox
                      v-model="cbxAll"
                      class="custom-control-danger"
                      name="check-button"
                      @change="val => toggleSelectAll(val)"
                    />
                  </th>
                  <th
                    scope="col"
                    width="10"
                  >
                    STT
                  </th>
                  <th
                    scope="col"
                    width="300"
                  >
                    {{ $t("name") }}
                  </th>
                  <th
                    scope="col"
                    width="300"
                  >
                    {{ $t("email") }}
                  </th>
                  <th
                    scope="col"
                    width="100"
                    class="text-center"
                  >
                    {{ $t("sdt") }}
                  </th>
                  <th
                    width="100"
                    scope="col"
                    :aria-sort="getSortDirection('created_at')"
                    @click="changeSortDirection('created_at')"
                    class="text-center"
                  >
                    {{ $t('dk') }}
                  </th>
                  <th
                    scope="col"
                    :aria-sort="getSortDirection('last_login')"
                    width="100"
                    class="text-center"
                    @click="changeSortDirection('last_login')"
                  >
                    {{ $t("Dngn") }}
                  </th>
                  <th
                    width="100"
                    scope="col"
                  >
                    {{ $t('source') }}
                  </th>
                  <th width="50">
                    {{ $t("opiton") }}
                  </th>
                </tr>
              </thead>
              <template v-if="rows.length">
                <template v-if="isLoading">
                  <tr
                    v-for="(item) in rows"
                  >
                    <td colspan="2">
                      <b-skeleton animation="fade" />
                    </td>
                    <td colspan="4">
                      <b-skeleton animation="fade" />
                    </td>
                    <td colspan="3">
                      <b-skeleton animation="fade" />
                    </td>
                  </tr>
                </template>
                <tr
                  v-for="(item, index) in rows"
                  v-else
                  :id="item.id"
                  :key="item.id"
                >
                  <td>
                    <b-form-checkbox
                      v-model="ids"
                      :value="item.id"
                      class="custom-control-danger"
                      name="check-button"
                    />
                  </td>
                  <td scope="row">
                    {{ index + 1 }}
                  </td>
                  <td><router-link
                    :to="{ name: 'accounts-edit', params: { id: item.id, name: item.name } }"
                  >
                    <template>{{ item.name }}</template>
                  </router-link></td>
                  <td>
                    <div class="d-flex align-items-center">
                      <span
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-tooltip.hover.top="item.status? 'Đang hoạt động' : 'Ngưng hoạt động'"
                        variant="outline-primary"
                        style="font-size: 8px"
                      >
                        <i
                          :class="item.status ? 'text-success' : 'text-danger '"
                          class="fas fa-circle xs"
                        />
                      </span>
                      <span style="margin-left: 10px">{{ item.email }}</span>
                    </div>
                  </td>
                  <td  class="text-center">{{ item.phone }}</td>
                  <td class="text-center">{{ convertDate(item.created_at) }}</td>
                  <td class="text-center">{{ convertDate(item.last_login) }}</td>
                  <td>
                    <template v-if="item.source == 1">
                      <b-badge variant="light-primary">
                        Google
                      </b-badge>
                    </template>
                    <template v-else-if="item.source == 2">
                      <b-badge variant="light-info">
                        Facebook
                      </b-badge>
                    </template>
                    <template v-else>
                      <b-badge variant="light-secondary">
                        Website
                      </b-badge>
                    </template>
                  </td>
                  <td>
                    <b-dropdown
                      id="dropdown-1"
                      class="custom-position-dropdown"
                      variant="flat-sencondary"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle"
                        />
                      </template>
                      <b-dropdown-item :to="{ name: 'accounts-edit', params: { id: item.id, name: item.name } }">
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">Sửa</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="deleteMulti(item.id)">
                        <feather-icon icon="TrashIcon" />
                        <span class="align-middle ml-50">Xóa</span>
                      </b-dropdown-item>
                      <b-dropdown-item   v-if="item.status" @click="lockAccount(item.id, 0)">
                        <feather-icon icon="LockIcon" />
                        <span class="align-middle ml-50">Khóa tk</span>
                      </b-dropdown-item>
                      <b-dropdown-item   v-else @click="lockAccount(item.id, 1)">
                        <feather-icon icon="UnlockIcon" />
                        <span class="align-middle ml-50">Mở khóa tk</span>
                      </b-dropdown-item>
                      <b-dropdown-item   @click="resetPass(item.id)">
                        <feather-icon icon="RefreshCcwIcon" />
                        <span class="align-middle ml-50"> Reset mk</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </template>
              <tr
                v-else
              >
                <td colspan="9">
                  <p class="text-center">
                    {{ $t('no_data') }}
                  </p>
                </td>
              </tr>
            </table>
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="pageLength"
              first-number
              last-number
              class="pagination-danger mt-2"
              align="right"
              @input="onPageChange"
            >
              <template #prev-text>
                <feather-icon
                  size="18"
                  icon="ChevronLeftIcon"
                />
              </template>
              <template #next-text>
                <feather-icon
                  size="18"
                  icon="ChevronRightIcon"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>

      </b-card-code>
    </b-col>
  </b-row>
</template>

<script>
import { localize } from 'vee-validate'
import vSelect from 'vue-select'
import config from '@/config'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow,
  BCol,
  BButton,
  VBModal,
  BFormInput,
  BFormSelect,
  BOverlay,
  BPagination,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BBadge,
  BSkeleton,
  BCard,
  VBTooltip,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardCode,
    BFormSelect,
    vSelect,
    BRow,
    BCard,
    BCol,
    BSkeleton,
    draggable,
    BButton,
    BFormInput,
    BOverlay,
    BPagination,
    BFormCheckbox,
    BDropdown,
    // eslint-disable-next-line vue/no-unused-components
    BDropdownItem,
    BBadge,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      cbxAll: false,
      modalShow: false,
      currentPage: 1,
      pageLength: 30,
      dragging: false,
      file: null,
      frm: {
        name: '',
        link: '',
        image: null,
        status: true,
      },
      // eslint-disable-next-line global-require
      imgUrl: null,
      required,
      isLoading: false,
      total: 0,
      tagOptions: [],
      rows: [],
      ids: [],
      modalName: '',
      sourceOption: config.source,
      endPoint: process.env.VUE_APP_API_ENDPOINT,
      filter: {
        key: '',
        tag: [],
        source: 'all',
      },
      sort: {
        by: 'id',
        direction: 'desc',
      },
    }
  },
  watch: {
    ids: {
      handler(value) {
        this.cbxAll = value.length && this._.difference(this.rows.map(v => v.id), value).length === 0
      },
    },
  },
  created() {
    this.loadItems()
  },
  methods: {
    deleteMulti(id) {
      this.$swal({
        title: this.$t('message.Confirm_delete'),
        text: false,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          if (typeof id === 'number') this.ids = [id]
          this.$http.delete('/accounts', {
            data: {
              ids: this.ids,
            },
          })
          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.$bvToast.toast(this.$t('message.delete_success'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'success',
                solid: false,
              })
              // eslint-disable-next-line no-unused-vars
            }).catch(e => {
              this.$bvToast.toast(this.$t('message.delete_err'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'danger',
                solid: false,
              })
            }).finally(() => {
              this.isLoading = false
              this.ids = []
              this.loadItems()
            })
        }
      })
    },
    toggleSelectAll(value) {
      if (value) {
        this.ids = this.rows.map(v => v.id)
      } else {
        this.ids = []
      }
    },
    onEnd(value) {
      if (value.oldIndex === value.newIndex) return
      const newOrder = this.rows[value.newIndex].order
      const oldOrder = this.rows[value.oldIndex].order
      if (newOrder === oldOrder) return
      if (value.newIndex < value.oldIndex) {
        this.changeOrder(newOrder, this.rows[value.newIndex + 1].order)
      } else {
        this.changeOrder(newOrder, this.rows[value.newIndex - 1].order)
      }
    },
    changeOrder(oldOrder, newOrder) {
      this.isLoading = true
      this.$http.put('/accounts', { oldOrder, newOrder })
      // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.$bvToast.toast(this.$t('message.update_success'), {
            title: this.$t('message.notify'),
            icon: 'BellIcon',
            variant: 'success',
            solid: false,
          })
          // eslint-disable-next-line no-unused-vars
        }).catch(e => {
          this.$bvToast.toast(this.$t('message.update_err'), {
            title: this.$t('message.notify'),
            icon: 'BellIcon',
            variant: 'danger',
            solid: false,
          })
        }).finally(() => {
          this.isLoading = false
          this.loadItems()
        })
    },
    onPageChange(currentPage) {
      this.currentPage = currentPage
      this.loadItems()
    },
    loadItems() {
      this.isLoading = true
      this.$http.get('/accounts', {
        params: {
          perPage: this.pageLength,
          page: this.currentPage,
          key: this.filter.key,
          source: this.filter.source,
          status: this.filter.status,
          order_by: this.sort.by,
          order_direction: this.sort.direction === 'ascending' ? 'asc' : 'desc',
        },
      })
        .then(res => {
          this.total = res.data.total
          this.pageLength = res.data.per_page
          this.rows = res.data.data.map(v => {
            // eslint-disable-next-line no-param-reassign
            v.status = v.status === 1
            return v
          })
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    onFileChange(e) {
      const file = e.target.files[0]
      this.imgUrl = URL.createObjectURL(file)
    },
    resetModal() {
      this.frm = {}
      this.imgUrl = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      localize('vi', {
        code: 'vi',
        messages: {
          required: 'Vui lòng nhập {_field_}',
        },
      })

      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isLoading = true
          const formData = new FormData()

          if (this.frm.image) formData.append('image', this.frm.image)
          formData.append('name', this.frm.name)
          formData.append('link', this.frm.link)
          formData.append('status', this.frm.status ? 1 : 0)
          if (this.frm.id) formData.append('_method', 'PUT')
          const url = this.frm.id ? `/accounts/${this.frm.id}` : '/accounts'
          this.$http({
            method: 'post',
            url,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.modalShow = false
              this.loadItems()

              this.$bvToast.toast(this.$t('message.update_success'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'success',
                solid: false,
              })
            })
          // eslint-disable-next-line no-unused-vars
            .catch(error => {
              let errorMsg = {}
              if (error.response.status === 422) {
                errorMsg = error.response.data
              } else {
                errorMsg.email = [error.response.data.error]
              }
              this.$refs.simpleRules.setErrors(errorMsg)
              this.$bvToast.toast(this.$t('message.update_err'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'danger',
                solid: false,
              })
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    handleEdit(item) {
      this.frm.id = item.id
      this.frm.name = item.name
      this.frm.link = item.link
      this.frm.status = item.status
      this.imgUrl = `${this.endPoint}/storage${item.image}`
    },
    handleModalShow() {
      this.modalName = this.frm.id ? this.$t('update') : this.$t('create')
    },
    getSortDirection(sortBy) {
      if (sortBy === this.sort.by) {
        return this.sort.direction
      }
      return 'none'
    },
    changeSortDirection(sortBy) {
      if (this.sort.by !== sortBy) {
        this.sort.direction = 'none'
        this.sort.by = sortBy
      }
      if (this.sort.direction === 'none') {
        this.sort.direction = 'ascending'
      } else if (this.sort.direction === 'ascending') {
        this.sort.direction = 'descending'
      } else {
        this.sort.by = 'order'
        this.sort.direction = 'desc'
      }
      this.loadItems()
    },
    lockAccount(id, status) {
      this.isLoading = true
      this.$http.put(`/accounts/${id}`, { status })
      // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.loadItems()
          this.$bvToast.toast(status ? this.$t('message.unlock_account') : this.$t('message.lock_account'), {
            title: this.$t('message.notify'),
            icon: 'BellIcon',
            variant: 'success',
            solid: false,
          })
          // eslint-disable-next-line no-unused-vars
        }).catch(e => {
          this.$bvToast.toast(this.$t('message.update_err'), {
            title: this.$t('message.notify'),
            icon: 'BellIcon',
            variant: 'danger',
            solid: false,
          })
        }).finally(() => {
          this.isLoading = false
        })
    },
    resetPass(id) {
      this.isLoading = true
      this.$http.put(`/accounts/${id}`, { resetPass: true })
      // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.loadItems()
          this.$bvToast.toast(this.$t('message.reset_pass'), {
            title: this.$t('message.notify'),
            icon: 'BellIcon',
            variant: 'success',
            solid: false,
          })
          // eslint-disable-next-line no-unused-vars
        }).catch(e => {
          this.$bvToast.toast(this.$t('message.update_err'), {
            title: this.$t('message.notify'),
            icon: 'BellIcon',
            variant: 'danger',
            solid: false,
          })
        }).finally(() => {
          this.isLoading = false
        })
    },
    convertDate(iosDate) {
      if (!iosDate) return ''
      const date = new Date(iosDate)
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let dt = date.getDate()

      if (dt < 10) {
        dt = `0${dt}`
      }
      if (month < 10) {
        month = `0${month}`
      }

      return `${dt}/${month}/${year}`
    },
    loginToAccount(id) {
      this.$http.get(`/login-to-account/${id}`)
        .then(res => {
          if (res.data.access_token) {
            window.open(`${process.env.VUE_APP_ELEARNING_URL}?access_token=${res.data.access_token}`, '_blank')
          }
        }).catch()
        .finally(() => {
        })
    },
  },
}
</script>
<style lang="scss">
.dark-layout {
.dropdown-item div {
  display: flex !important;
  gap: 3px;
}
}
</style>
